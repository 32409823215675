/** @format */

import PageHeader from "../components/elements/PageHeader";
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import ShortenText from "../components/elements/medium/ShortenText";
import moment from "moment";
import Totext from "../components/elements/medium/Totext";
import { BsCalendarWeek } from "react-icons/bs";

function News() {
  const [mediumData, setMediumData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(
      `https://mediumpostsapi.vercel.app/api/@relymernews`,
    )
      .then(res => res.json())
      .then(response => {
        setMediumData(response?.dataMedium);
        setIsLoading(false);
        // console.log(response.items);
      })
      .catch(err => console.log(err));
  }, []);
  return (
    <div className='news-page'>
      <PageHeader title='Latest News' />
      {isLoading && <p>Fetching data from Medium!</p>}

      <Container className='mt-4'>
        <Row>
          {mediumData?.map(article => (
            <Col md='4' className='mb-3' key={article.guid}>
              <div className='horizontol-card mb-3'>
                <div className='wrapper'>
                  <img src={article.image} alt='' />
                </div>
                <div className='content p-4'>
                  <h4 className='heading my-3'>
                    {ShortenText(Totext(article.title), 0, 80) + "..."}
                  </h4>
                  <div className='d-flex gap-2 justify-content-between'>
                    <a href={article.link} target="_blank" rel="noreferrer" className='link-btn'>
                      <img src='/assets/arrow-up-right.png' alt='' />
                    </a>
                    <div className='text-silver ms-3 d-flex align-items-center gap-1'>
                      <BsCalendarWeek />
                      <span>
                        &nbsp;
                        {moment(article.date).format("MMM DD, YYYY")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}
export default News;
