/** @format */

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import React, { useState, useEffect } from "react";

import ShortenText from "../elements/medium/ShortenText";
import ToText from "../medium/ToText";
function Slider() {
  const [mediumData, setMediumData] = useState([]);
  useEffect(() => {
    fetch(
      `https://mediumpostsapi.vercel.app/api/@relymernews`,
    )
      .then(res => res.json())
      .then(response => {
        setMediumData(response?.dataMedium);
        // console.log(response.items);
      })
      .catch(err => console.log(err));
  }, []);

  const finalData = mediumData?.slice(0, 4);
  const allData = mediumData?.slice(0, 7);

  return (
    <div className='container '>
      <div className='row'>
        <div className='col-md-8 pt-5'>
          <Carousel showThumbs={true}>
            {allData?.map(article => (
              <a
                href={article.link}
                target='_blank'
                rel='noopener noreferrer'
                key={article.guid}>
                <div className='slider'>
                  <img
                    src={article.image}
                    alt=''
                    className='w-100  rounded-3 '
                  />
                </div>
              </a>
            ))}

            {/* <div>
              <div className='slider'>
                <img
                  src='https://cdn-images-1.medium.com/max/800/1*yMIhIic0zZ_6agIC1JyiHg.png'
                  alt=''
                  className='w-100  rounded-3 '
                />
              </div>
            </div>
            <div>
              <div className='slider'>
                <img
                  src='https://cdn-images-1.medium.com/max/1024/1*7f7UfV7iPVHPi_EBSrLjGg.png'
                  alt=''
                  className='w-100  rounded-3 '
                />
              </div>
            </div> */}
          </Carousel>
        </div>
        <div className='col-md-4 m-auto'>
          <h2 className='text-neutral fw-bold '>Recent news</h2>
          <div className='d-flex flex-column gap-4 mt-3'>
            {finalData?.map(article => (
              <a
                href={article.link}
                target='_blank'
                rel='noopener noreferrer'
                key={article.guid}>
                <div className='row vertical-card py-2'>
                  <div className='col-4 m-auto'>
                    <img
                      src={article.image}
                      className='d-block w-100 rounded-3'
                      alt=''
                    />
                  </div>
                  <div className='col-8 m-auto'>
                    <div className='content px-2'>
                      <p className='text-silver fw-bold'>
                        {ShortenText(ToText(article.title), 0, 50) + "..."}
                      </p>
                      {/* <hr className='my-2' /> */}
                      {/* <div className='d-flex'>
                      <a href={article.link} className='link-btn '>
                        <img src='/assets/arrow-up-right.png' alt='' />
                      </a>
                    </div> */}
                    </div>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Slider;
