/** @format */

import { BsArrowRight } from "react-icons/bs";
import data from "../../lib/trendingTopics.json";

function TrendingTopics() {
  return (
    <div className='trending-topics container'>
      <div className='d-flex justify-content-between align-items-center'>
        <h2 className='text-neutral fw-bold'>Trending Topics</h2>
        <a href='/' className='btn view-all text-silver text-align-end'>
          VIEW ALL
          <BsArrowRight />
        </a>
      </div>

      <div className='grid-content mt-4'>
        {data.map((item, i) => (
          <div
            className='bg-card'
            key={i}
            style={{ backgroundImage: `url('${item.img}')` }}>
            <div className='content'>
              <p className='primary-badge'>
                <span>{item.type}</span>
              </p>
              <h4 className={`heading ${i === 0 && "md-heading"}`}>
                {item.heading}
              </h4>
              {/* <a href='#!' className='link-btn'>
                <img src='/assets/arrow-up-right.png' alt='' />
              </a> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default TrendingTopics;
