/** @format */

import { Link } from "react-router-dom";

function NotFound() {
  return (
    <>
      <div
        className='container text-center d-flex flex-column justify-content-center align-items-center  '
        style={{ minHeight: "60vh" }}>
        <h2 className='display-4'>
          404 <span className='text-primary'>Error</span>
        </h2>
        <p className='lead'>Requested page is not Found</p>
        <Link to='/' className='btn btn-dark block'>
          Back to Home
        </Link>
      </div>
    </>
  );
}
export default NotFound;
