/** @format */

import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import ShortenText from "../elements/medium/ShortenText";
import moment from "moment";
import ToText from "./ToText";
import { BsCalendarWeek } from "react-icons/bs";
import { Link } from "react-router-dom";

const Blog = () => {
  const [mediumData, setMediumData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(
      `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@relymerfeatured`,
    )
      .then(res => res.json())
      .then(response => {
        setMediumData(response.items);
        setIsLoading(false);
        // console.log(response.items);
      })
      .catch(err => console.log(err));
  }, []);

  const finalData = mediumData?.slice(0, 3);

  return (
    <div id='blog' className='container'>
      {isLoading && <p>Fetching data from Medium!</p>}
      <div>
        <Row>
          {finalData?.map(article => (
            <Col md='4' className='mb-3' key={article.guid}>
              <div className='horizontol-card mb-3 position-relative'>
                <div className='wrapper'>
                  <img src={article.thumbnail} alt='' />
                </div>
                <div className='feat'>Featured</div>

                <div className='content p-4'>
                  <p>
                    <span className='text-uppercase text-primary fw-bold'>
                      Latest
                    </span>
                  </p>

                  <h4 className='heading my-3'>
                    {ShortenText(ToText(article.title), 0, 80) + "..."}
                  </h4>
                  <div className='d-flex gap-2 justify-content-between'>
                    <a
                      href={article.link}
                      target='_blank'
                      rel='noreferrer'
                      className='link-btn'>
                      <img src='/assets/arrow-up-right.png' alt='' />
                    </a>
                    <div className='text-silver ms-3 d-flex align-items-center gap-1'>
                      <BsCalendarWeek />
                      <span>
                        &nbsp;
                        {moment(article.pubDate).format("MMM DD, YYYY")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>

        <Link to='/featured'>
          <button className='btn-read m-auto d-block mt-3'>Read more</button>{" "}
        </Link>
      </div>
    </div>
  );
};

export default Blog;
