/** @format */

import PageHeader from "../components/elements/PageHeader";
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import ShortenText from "../components/elements/medium/ShortenText";
import moment from "moment";
import Totext from "../components/elements/medium/Totext";
import { BsCalendarWeek } from "react-icons/bs";

function Featured() {
  const [mediumData, setMediumData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(
      `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@relymerfeatured`,
    )
      .then(res => res.json())
      .then(response => {
        console.log(response.items);
        setMediumData(response.items);
        setIsLoading(false);
      })
      // .catch(err => console.log(err));
  }, []);
  // console.log(mediumData);

  return (
    <div className='news-page'>
      <PageHeader title='Featured' />
      {isLoading && <p>Fetching data from Medium!</p>}

      <Container className='mt-4'>
        <Row>
          {mediumData?.map(article => (
            <Col md='4'  className='mb-3' key={article.guid}>
              <div className='horizontol-card mb-3 position-relative'>
                <div className='wrapper'>
                  <img src={article.thumbnail} alt='' />
                </div>
                <div className='feat'>Featured</div>
                <div className='content p-4'>
                  <h4 className='heading my-3'>
                    {ShortenText(Totext(article.title), 0, 80) + "..."}
                  </h4>
                  <div className='d-flex gap-2 justify-content-between'>
                    <a
                      href={article.link}
                      target='_blank'
                      rel='noreferrer'
                      className='link-btn'>
                      <img src='/assets/arrow-up-right.png' alt='' />
                    </a>
                    <div className='text-silver ms-3 d-flex align-items-center gap-1'>
                      <BsCalendarWeek />
                      <span>
                        &nbsp;
                        {moment(article.pubDate).format("MMM DD, YYYY")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}
export default Featured;
