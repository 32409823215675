/** @format */

import { BrowserRouter, Routes, Route } from "react-router-dom";
// pages
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Base from "./components/layouts/Base";
import "bootstrap/dist/css/bootstrap.min.css";
import "./app.scss";
import AboutUs from "./pages/AboutUs";
import News from "./pages/News";
import Featured from "./pages/Featured";
import Contact from "./pages/Contact";

function App() {
  return (
    <BrowserRouter>
      <Base>
        <Routes>
          <Route index path='/' element={<Home />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/news' element={<News />} />
          <Route path='/featured' element={<Featured />} />
          <Route path='/*' element={<NotFound />} />
          <Route path='/contact' element={<Contact />} />
          {/* // <Route path='/read' element={<Slider />} /> */}
        </Routes>
      </Base>
    </BrowserRouter>
  );
}
export default App;
