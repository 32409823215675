/** @format */

function PageHeader({ title, title1, title2 }) {
  return (
    <div className='page-header'>
      <h3 className='display-6 fw-bold  text-center'>{title}</h3>
      <h3 className='display-6 fw-bold  text-center'>{title1}</h3>
      <h3 className='display-6 fw-bold  text-center'>{title2}</h3>
    </div>
  );
}
export default PageHeader;
