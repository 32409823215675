/** @format */

// import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import ShortenText from "../elements/medium/ShortenText";
import moment from "moment";
import ToText from "../medium/ToText";
import { BsCalendarWeek } from "react-icons/bs";

function PopularBlogs({ heading }) {
  const [mediumData, setMediumData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(
      `https://mediumpostsapi.vercel.app/api/@relymernews`,
    )
      .then(res => res.json())
      .then(response => {
        setMediumData(response?.dataMedium);
        setIsLoading(false);
        // console.log(response.items);
      })
      .catch(err => console.log(err));
  }, []);

  const finalData = mediumData?.slice(0, 3);

  return (
    <div className='popular-blogs container'>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <h2 className='text-neutral fw-bold'>{heading}</h2>
        {/* <a href="/" className="btn view-all text-silver text-align-end">
          VIEW ALL
          <BsArrowRight />
        </a> */}
      </div>

      {isLoading && <p>Fetching data from Medium!</p>}
      <>
        <Row>
          {finalData?.map(article => (
            <Col md='4' className='mb-3' key={article.link}>
              <div className='horizontol-card mb-3 position-relative'>
                <div className='wrapper'>
                  <img src={article.image} alt='' />
                </div>
                {/* <div className='feat'>Featured</div> */}

                <div className='content p-4'>

                  <h4 className='heading my-3'>
                    {ShortenText(ToText(article.title), 0, 80) + "..."}
                  </h4>
                  <div className='d-flex gap-2 justify-content-between'>
                    <a
                      href={article.link}
                      target='_blank'
                      rel='noreferrer'
                      className='link-btn'>
                      <img src='/assets/arrow-up-right.png' alt='' />
                    </a>
                    <div className='text-silver ms-3 d-flex align-items-center gap-1'>
                      <BsCalendarWeek />
                      <span>
                        &nbsp;
                        {moment(article.date).format("MMM DD, YYYY")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>

        <Link to='/news'>
          <button className='btn-read m-auto d-block mt-3'>Read more</button>{" "}
        </Link>
      </>
    </div>
  );
}
export default PopularBlogs;
