/** @format */

// componenets
import FeaturedTopics from "../components/elements/FeaturedTopics";
import PageHeader from "../components/elements/PageHeader";
import PopularBlogs from "../components/elements/PopularBlogs";
import Slider from "../components/elements/Slider";
import TrendingTopics from "../components/elements/TrendingTopics";

function Home() {
  return (
    <>
      {/* <coingecko-coin-price-marquee-widget
        coin-ids='bitcoin,ethereum,binancecoin,polkadot,solana,avalanche-2,moonbeam,cardano,matic-network,aave,arbitrum,fantom,tether,ripple,usd-coin,dogecoin,wrapped-bitcoin,dai,shiba-inu,optimism,tezos'
        currency='usd'
   
        background-color='#f4f6f9'
        locale='en'></coingecko-coin-price-marquee-widget> */}
      <div
        class='binance-widget-marquee'
        data-cmc-ids='1,1027,1839,52,3408,5426,74,3890,5805,6636,6836,4943'
        data-theme='light'
        data-transparent='true'
        data-locale='en'></div>

      <Slider />
      <div className='my-5'>
        <PopularBlogs heading='Popular News' />
      </div>
      <PageHeader
        title="Unveiling Tomorrow's Tech, Today!"
        title1='With Relymer News sail'
        title2='Beyond the Headlines, Into the Future!'
      />
      <div className='my-5'>
        <TrendingTopics />
      </div>

      <FeaturedTopics />
    </>
  );
}
export default Home;
