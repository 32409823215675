/** @format */

import PageHeader from "../components/elements/PageHeader";

function AboutUs() {
  return (
    <>
      <PageHeader title1='Navigating the Digital Frontiers' />

      <div className='container text-neutral my-5'>
        <h2 className='text-neutral fw-bold mb-3'>Welcome</h2>
        {/* <h4 className="mb-4 fw-normal" style={{ fontSize: "22px" }}>
        Your Daily insight  into the worlds of cryptocurrency and blockchain 
        </h4> */}
        <p>
          Welcome to Relymer News, where we decode the complexities of
          blockchain, cryptocurrency, and technology to bring you insightful
          articles that transcend the ordinary. Relymer News isn't just a news
          platform; it's your compass in the ever-evolving landscape of digital
          innovation.
        </p>

        <img
          src='/assets/aboutimg.png'
          alt=''
          className='img-fluid mx-auto d-block my-5'
        />
      </div>

      <div className='container text-neutral my-5'>
        <h2 className='text-neutral fw-bold mb-3'>Our Editorial Ethos</h2>
        <p>
          At Relymer News, we believe in delivering more than just news; we
          strive to provide perspectives that unravel the intricacies of the
          crypto and tech world. Our articles are crafted with a commitment to
          clarity, relevance, and the genuine empowerment of our readers.
        </p>

        <img
          src='/assets/about2.png'
          alt=''
          className='img-fluid mx-auto d-block my-5 rounded-2'
        />
      </div>

      <div className='container text-neutral my-5'>
        <h2 className='text-neutral fw-bold mb-3'>What Drives Us</h2>
        <p>
          Fuelled by a passion for staying at the forefront of technological
          advancements, Relymer News is a hub for those hungry for in-depth
          insights. Whether you're a seasoned crypto enthusiast, a tech
          aficionado, or a curious reader, our articles are designed to cater to
          your intellect and curiosity.
        </p>
      </div>

      <div className='container text-neutral my-5'>
        <h2 className='text-neutral fw-bold mb-3'>
          Dive into the Digital Narrative
        </h2>
        <p>
          Relymer News isn't just about reporting events; it's about weaving
          narratives that capture the pulse of the digital realm. We delve into
          the stories behind the headlines, offering a comprehensive
          understanding of the forces shaping the crypto and tech landscapes.
        </p>
      </div>

      <div className='container text-neutral my-5'>
        <h2 className='text-neutral fw-bold mb-3'>Why Relymer News</h2>
        <ul>
          <li>
            In-Depth Analysis: Our articles go beyond surface-level reporting,
            providing you with analyses that uncover the nuances of each
            development.
          </li>
          <li>
            Timely Updates: Stay ahead with Relymer News. We're committed to
            delivering timely updates on the latest in blockchain,
            cryptocurrency, and technology.
          </li>
          <li>
            Community Engagement: Relymer News is more than a platform; it's a
            community. Engage with us through comments, discussions, and shared
            insights.
          </li>
        </ul>
      </div>

      <div className='container text-neutral my-5 text-center '>
        <h2 className='text-neutral fw-bold mb-3'>Join the Conversation</h2>
        <p>
          Ready to explore the world of crypto and tech through a new lens? Join
          us at Relymer News, where every article is an invitation to dive into
          the digital revolution. Have questions or want to contribute? Reach
          out, and let's build a community driven by knowledge and curiosity.
        </p>
      </div>
    </>
  );
}
export default AboutUs;
