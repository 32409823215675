/** @format */

// layout
import Header from "./Header";
import Footer from "./Footer";

const Base = props => {
  return (
    <div style={{ minHeight: "100vh" }}>
      <Header />
      <main role='main'>{props.children}</main>
      <Footer />
    </div>
  );
};
export default Base;
