import VCard from "./VCard";
import { Col, Container, Row } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
// data
import data from "../../lib/featuredData.json";
import Slider from "./medium/Slider";
import ShowBlog from '../medium/ShowBlog'
function FeaturedTopics() {
  return (
    <div className="featured-topics my-5">
      <Container>
        <Row>
          <Col lg={12} className="d-flex flex-column gap-4 my-4">
            <h2 className="text-neutral fw-bold mb-4">Featured Topics</h2>
            {/* {data.featured.map((item, i) => (
              <VCard key={i} data={item} />
            ))} */}
            <ShowBlog/>
          </Col>


        </Row>
      </Container>
    </div>
  );
}
export default FeaturedTopics;
