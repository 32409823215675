import React from "react";

function Contact() {
  return (
    <div className="container pt-5 pb-5">
      <div className="row">
        <div className="col-md-12">
          <div className="form">
            <p>Contact us</p>
            <h2>Get in touch</h2>
            <h3>We’d love to hear from you. Please fill out this form.</h3>
            <div className="row mt-5">
              <div className="col-md-6">
                <label htmlFor="First Name">First Name</label> <br />
                <input type="text" name="" id="" />
              </div> 
              <div className="col-md-6">
                <label htmlFor="First Name">Last Name</label> <br />
                <input type="text" name="" id="" />
              </div>
            </div>
            <label htmlFor="Email">Email</label> <br />
            <input type="email" name="" id="" className="w-100" />
            <label htmlFor="Phone">Phone No</label> <br />
            <input type="number" name="" id="" className="w-100" />
            <label htmlFor="Message">Message</label> <br />
            <textarea name="" id="" cols="30" rows="10" className="w-100"></textarea>
            <div className="d-flex align-items-baseline">
            <input type="checkbox" name="" id="" style={{width:'20px'}} className='mt-1'/>
            <label htmlFor="">You agree to our friendly privacy policy.</label>
            </div>
            <button>Send message</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
