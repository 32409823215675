/** @format */

import { BsFacebook, BsInstagram, BsLinkedin, BsTwitter } from "react-icons/bs";
import Banner from "../elements/Banner";

function Footer() {
  return (
    <>
      <Banner />
      <footer className='footer'>
        {/* <div className='container py-4 px-2'>
        <div className='row'>
          <div className='col-md-4 col-12'>
            <img src='assets/asset 10.svg' alt='' width={90} />
            <p className='mt-3'>
            Design amazing digital experiences that create more happy in the
            world.
            </p>
          </div>

          <div className='col-md-1'></div>
          
          <div className='col-md-1 col-4'>
          <h4 className='text-silver'>Products</h4>
          <ul className='list-unstyled'>
              <li>
                <a href='#'>Overview</a>
              </li>
              <li>
                <a href='#'>Features</a>
              </li>
              <li>
                <a href='#'>Solutions</a>
                </li>
              <li>
                <a href='#'>Tutorials</a>
              </li>
              <li>
                <a href='#'>Pricing</a>
                </li>
                <li>
                <a href='#'>Releases</a>
              </li>
            </ul>
          </div>
          <div className='col-md-1 col-4'>
            <h4 className='text-silver'>Company</h4>
            <ul className='list-unstyled'>
              <li>
                <a href='#'>About us</a>
              </li>
              <li>
                <a href='#'>Careers</a>
              </li>
              <li>
                <a href='#'>Press</a>
              </li>
              <li>
                <a href='#'>News</a>
              </li>
              <li>
                <a href='#'>Media kit</a>
              </li>
              <li>
                <a href='#'>Contact</a>
              </li>
            </ul>
          </div>
          <div className='col-md-1 col-4'>
            <h4 className='text-silver'>Resources</h4>
            <ul className='list-unstyled'>
              <li>
                <a href='#'>Blog</a>
              </li>
              <li>
                <a href='#'>Newsletter</a>
              </li>
              <li>
                <a href='#'>Events</a>
              </li>
              <li>
                <a href='#'>Help centre</a>
              </li>
              <li>
                <a href='#'>Tutorials</a>
              </li>
              <li>
              <a href='#'>Support</a>
              </li>
            </ul>
          </div>
          <div className='col-md-1 col-4'>
            <h4 className='text-silver'>Social</h4>
            <ul className='list-unstyled'>
            <li>
            <a href='#'>Twitter</a>
              </li>
              <li>
                <a href='#'>LinkedIn</a>
              </li>
              <li>
                <a href='#'>Facebook</a>
                </li>
              <li>
                <a href='#'>GitHub</a>
              </li>
              <li>
                <a href='#'>AngelList</a>
              </li>
              <li>
                <a href='#'>Dribble</a>
                </li>
                </ul>
                </div>
          <div className='col-1'>
            <h4 className='text-silver'>Legal</h4>
            <ul className='list-unstyled'>
              <li>
                <a href='#'>Terms</a>
              </li>
              <li>
                <a href='#'>Privacy</a>
              </li>
              <li>
                <a href='#'>Cookies</a>
                </li>
              <li>
              <a href='#'>Licenses</a>
              </li>
              <li>
                <a href='#'>Settings</a>
              </li>
              <li>
                <a href='#'>Contact</a>
              </li>
            </ul>
          </div>

          <div className='col-1'></div>
        </div>
      </div> */}

        <div className='bottom-footer text-silver'>
          <div className='container py-4 px-2'>
            <img src='assets/asset 10.svg' alt='' width={50} />

            <span>© 2024 Relymer News. All rights reserved.</span>
            {/* <div className='icons'>
              <BsTwitter />
              <BsLinkedin />
              <BsFacebook />
              <BsInstagram />
            </div> */}
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
