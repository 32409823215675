import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";

function Header() {
  return (
    <header className="header">
      <Navbar expand="lg" sticky="top">
        <Container>
          <Navbar.Brand className="text-gradient logo-text" href="/">
            <img src="assets/asset 10.svg" alt="" width={50}/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <FaBars />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <NavLink text="Home" href="/" />
              <NavLink text="About us" href="/about-us" />
              <NavLink text="News" href="/news" />
              <NavLink text="Featured" href="/featured" />

              <Link to="/contact" className="button-primary nav-btn">
                Contact us
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}
export default Header;

function NavLink({ text, href }) {
  const { pathname } = useLocation();

  return (
    <Link
      to={href}
      className={`nav-link me-5 ${pathname === href ? "active" : ""}`}
    >
      {text}
    </Link>
  );
}
