/** @format */

function Banner() {
  return (
    <div className='banner container-fluid'>
      <div className='container'>
        <div className='content '>
          {/* <p className='red-badge'>25% off</p> */}
          <h4>
            Stay Informed, Stay Inspired. Connect with the Future Check Ready
            for More? Check Out Trending Topics Insights!!
          </h4>

          <div className='d-flex  justify-content-between align-items-center'>
            {/* <hr style={{ width: "80%" }} /> */}
            <a href='https://relymer.com' className='link-btn'>
              <img src='/assets/arrow-up-right.png' alt='' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Banner;
